/* .page {
  width: 29.7cm;
  height: 21cm;
  display: flex;
  flex-wrap: wrap;
}

.price-container {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.price-card {
  text-align: center;
}

.empty-card {
  background-color: #f0f0f0;
}

.price-our {
  font-size: 0.5cm;
  font-weight: bold;
  margin-bottom: 0;
}

.price-value {
  font-size: 2cm;
  font-weight: bold;
  margin-bottom: 0;
}

.currency-symbol {
  font-size: 1.5cm;
  vertical-align: super;
}

.price-description {
  font-size: 1.5cm;
}

.item-name {
  margin-bottom: 0.2cm;
}

.item-name-hindi {
  font-size: 1.2cm;
} */

.price-container {
  width: 29.7cm;
  height: 21cm;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px solid black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.wq-logo {
  position: absolute;
  height: 3cm;
  width: 3cm;

  top: 0.5cm;
  left: 0.5cm;
}

.price-card {
  text-align: center;
}

.price-value {
  font-size: 11cm;
  font-weight: bold;
  margin-top: -3cm;
}

.hr {
  margin-top: -2cm;
  color: #000;
}

.our-price {
  font-size: 2cm;
  font-weight: bold;
}

.currency-symbol {
  font-size: 2cm;
  vertical-align: super;
}
.currency-symbol-sup {
  font-size: 2cm;
  /* vertical-align: sub; */
  margin-top: -2cm;
}

.price-description {
  font-size: 2cm;
  font-weight: 700;
}

.item-name {
  margin-bottom: 0.2cm;
}

.item-name-hindi {
  font-size: 1.5cm;
}
